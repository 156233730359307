<template>
  <div class="rating-type">
    <div class="rating-containter">

    <div class="divTable" :class="fullWidthTable">
      <div class="divTableRow">
        <div class="divTableCell">
          <div :class="'rating-block ' + component.properties.icon + ' ' + answered">
            <template v-for="key in iterateArray">
              <input type="radio" :id="'ratingType-' + component.id + '-' + key[0]" :name="'ratingType-' + component.id" :value="key[0]" @change="updateData" tabindex="-1">
              <label :for="'ratingType-' + component.id + '-' + key[0]" ref="ratingItem" class="tabable" tabindex="-1">

                <div v-if="component.properties.icon == 'opinion_scale' || component.properties.icon == 'numbers'">{{ key[0] }}</div>
                <div v-else-if="component.type == 'customer_sat' && component.properties.icon == 'animated-smileys'">
                  <img :src="'/images/animated-smileys/' + key[1] + '.gif'">
                </div>
                <div v-else-if="component.type == 'customer_sat' && component.properties.icon == 'emoji'">
                  {{ key[1] }}
                </div>
                <svg-icon v-else :icon="key[1]"></svg-icon>
              </label>
            </template>
          </div>
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableCell">
          <div class="rating-labels">
              <div class="rating-label" v-show="showEdgeLabels">
                  {{ component.properties.labels[0] }}
              </div>
              <div class="rating-label" v-show="showCenterLabel">
                  {{ component.properties.labels[1] }}
              </div>
              <div class="rating-label" v-show="showEdgeLabels">
                  {{ component.properties.labels[2] }}
              </div>
          </div>
      </div>
      </div>
    </div>


    </div>
    <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>
  </div>
</template>

<script>
import Vue from 'vue'
import { store } from '../store'
import SvgIcon from './svg-icon';

export default {
  name: 'ratingType',
  props: ['component'],
  data: function () {
    return {
      store: store,
      messages: window.form.messages,
      answered: ''
    }
  },
  components: {
    'svg-icon': SvgIcon,
  },
  created: function () {
    if (this.component.properties.required) {
      Vue.set(this.component, 'required_show_label', 0)
    }
  },
  computed: {
    fullWidthTable: function () {
      if (this.component.properties.icon == 'numbers') {
        return 'fullWidth';
      }
    },
    iterateArray: function() {
      if (this.component.type == 'opinion_scale') {
        return Array.from({length: this.component.properties.icon_count}, (v, k) => [k, this.component.properties.icon]).reverse()
      } else if (this.component.type == 'customer_sat' && this.component.properties.icon == 'emoji') {
        switch (this.component.properties.icon_count) {
          case 5:
            return new Array([5, '😍'], [4, '😃'], [3, '😐'], [2, '😒'], [1, '😔'])
          case 4:
            return new Array([4, '😍'], [3, '😃'], [2, '😒'], [1, '😔'])
          case 3:
            return new Array([3, '😍'], [2, '😐'], [1, '😔'])
          default: 
            return new Array([2, '😍'], [1, '😔'])
        }

      } else if (this.component.type == 'customer_sat' && this.component.properties.icon == 'numbers') {
        return Array.from({length: this.component.properties.icon_count}, (v, k) => [k+1, this.component.properties.icon]).reverse()
      
      } else if (this.component.type == 'customer_sat') {
        let icon = this.component.properties.icon
        switch (this.component.properties.icon_count) {
          case 5:
            return new Array([5, icon + '-love'], [4, icon + '-happy'], [3, icon + '-normal'], [2, icon + '-sad'], [1, icon + '-angry'])
            break

          case 4:
            return new Array([4, icon + '-love'], [3, icon + '-happy'], [2, icon + '-sad'], [1, icon + '-angry'])
            break

          case 3:
            return new Array([3, icon + '-love'], [2, icon + '-normal'], [1, icon + '-angry'])
            break

          default: 
            return new Array([2, icon + '-love'], [1, icon + '-angry'])
            break
        }
      } else {
        return Array.from({length: this.component.properties.icon_count}, (v, k) => [k+1, this.component.properties.icon]).reverse()
      }
    },
    showCenterLabel: function () {
      if (Number(this.component.properties.icon_count) > 9) {
        return true
      } else {
        return false
      }
    },
    showEdgeLabels: function () {
      if (Number(this.component.properties.icon_count) > 4) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateData: function (e) {

      Vue.set(this.store.answers, this.component.ref, {
        "component": {
            "id": this.component.id,
            "type": this.component.type
        },
        "type": "number",
        "number": e.target.value
     })

     this.answered = 'answered'

      Event.$emit('verifyPage')

      if (this.component.validations.required && e.target.value) {
        Vue.set(this.component, 'required_show_label', 0)
      }
    }
  }
}
</script>

<style lang="scss">

.divTable{
	display: table;
	width: auto;
  &.fullWidth {
    width: 100%;
  }
}
.divTableRow {
	display: table-row;
}
.divTableCell {
	display: table-cell;
}

.rating-type {
  display: inline-block;
  width: 100%;
}
.rating-container {
  display: flex; 
  flex-direction: column;
}
.rating-block {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 15px;
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
    margin: -1px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    overflow: hidden;
  }
  label {
    max-width: 52px;
    max-height: 52px;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    flex: 1 0 0%;
    &:first-of-type {
      margin-right: 0;
    }
    @media ( max-width: 420px ) {
      margin-right: 5px;
    }
    .svg-container {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: column;
      flex: 1 1 0%;
      svg {
        display: block; 
        width: 100%; 
        height: auto;
        path.symbolFill {
          fill: none;
        }
      }
    }
    // focus mode
    // position: relative;
    // &:focus {
    //   &:before {
    //     border-bottom: 2px solid black;
    //     position: absolute;
    //     height: 2px;
    //     bottom: -8px;
    //     left: 0;
    //     right: 0;
    //     content: '';
    //   }
    // }
  }
  &:not(:checked):not(.smileys):not(.outline-smileys):not(.animated-smileys) {
    > label {
      &:hover,
      &:hover ~ label {
        svg {
          fill: #49c62b;
          path.symbolFill {
            fill: #49c62b;
          }
        }
      }
    }
  }
  &:not(.smileys):not(.outline-smileys):not(.animated-smileys) input:checked ~ label {
    .svg-container {
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      svg {
        fill: #49c62b;
        path.symbolFill {
          fill: #49c62b;
        }
      }
    }
  }

  &.numbers {
    > label {
      background: rgba(0,0,0,0.05);
      div {
        font-size: 1.8rem;  
        line-height: 3.3rem;  
        @media ( max-width: 420px ) {
          font-size: 1.2rem;  
          line-height: 2.6rem;  
        }
      }
      border: 1px solid #ccc;
      border-width: 1px 0 1px 1px;
      padding: rem-calc(10) 0;
      text-align: center;
      margin-right: 0;
      max-width: 100%; //64px;
      &:first-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right-width: 1px;
      }
      &:last-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    label:hover {
      background: rgba(0,0,0,0.1);
    }
    input:checked + label {
      background: green;
      color: white;
      &:hover {
        color: white;
      }
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
  
  }

  &.animated-smileys {
    > label {
      border-radius: 100%;
      max-width: 110px; 
      max-height: 110px; 
      padding: 0;
      margin: 0;
      outline: none;
      margin-right: -10px;
      @media ( max-width: 420px ) {
        margin-right: 0px;
      }
      img {
        border-radius: 50%;
        object-fit: cover;
        transition: transform .2s;
      }
      &:hover img {
        transform: scale(1.3); 
      }
    }
    &.answered {
      input:not(:checked) + label {
        img {
          opacity: .5;
        }
      }
    }
    input:checked + label {
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      img,
      img:hover {
        transform: scale(1); 
      }
    }
  }

  &.outline-smileys {
    > label {
      border-radius: 100%;
      max-width: 80px; 
      max-height: 80px; 
      padding: 0;
      outline: none;
      border: 4px solid transparent;
      margin: 0 5px 0 0;
      @media ( max-width: 420px ) {
        margin-right: 0px;
      }
      &:hover {
        svg {
          fill: #49c62b;
        }
      }
    }
    input:checked + label {
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      svg {
        fill: #49c62b;
      }
    }
  }

  &.smileys {
    > label {
      border-radius: 100%;
      max-width: 90px; 
      max-height: 90px; 
      padding: 0;
      margin: 0 10px 0 0;
      outline: none;
      border: 3px solid transparent;
      transition: transform .2s;
      background: white;
      &:hover {
        // border-color: #ffffff;
        transform: scale(1.2); 
      }
    }
    input:checked + label {
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      // border: 5px solid green;
      transform: scale(1); 
      &:hover {
        transform: scale(1); 
      }
    }
    &.answered {
      input:not(:checked) + label {
        opacity: .4;
      }
    }
  }

  &.emoji {
    > label {
      border-radius: 100%;
      max-width: 90px; 
      max-height: 90px; 
      font-size: 75px;
      @media ( max-width: 520px ) {
        font-size: 55px;
      }
      @media ( max-width: 420px ) {
        font-size: 45px;
      }
      @media ( max-width: 360px ) {
        font-size: 35px;
      }
      padding: 0;
      margin: 0 10px 0 0;
      outline: none;
      border: 3px solid transparent;
      transition: transform .2s;
      background: transparent;
      &:hover {
        transform: scale(1.2); 
      }
    }
    input:checked + label {
      animation-name: hideshow;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      transform: scale(1); 
      &:hover {
        transform: scale(1); 
      }
    }
    &.answered {
      input:not(:checked) + label {
        opacity: .4;
      }
    }
  }

}

@keyframes hideshow {
  0% { opacity: 0; }
  20% { opacity: 1; }
  40% { opacity: 0; }
  60% { opacity: 1; }
  80% { opacity: 0; }
  100% { opacity: 1; }
} 


.rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  div {
    flex-grow: 1;
    font-size: 16px;
    color: grey;
  }
  > div:last-of-type {
    text-align: right;
  }
  > div:first-of-type {
    text-align: left;
  }
  div:nth-child(1):nth-last-child(1) {
    text-align: center;
  }
  div:nth-child(2):nth-last-child(1) {
    text-align: right;
  }
  div:nth-child(2):nth-last-child(2) {
    text-align: center;
  }
  div:nth-child(3):nth-last-child(1) {
    text-align: right;
  }
}
</style>