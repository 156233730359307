<template>
 <div class="open-type">

    <textarea
            v-if="component.type == 'text' && component.properties.multiple_lines"
            :id="'component_' + component.id"
            v-model="inputValue"
            @input="updateData($event)"
            @keyup="autosize($event)"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false"
            tabindex="-1"
            class="tabable"
    ></textarea>

    <input type="text"
            v-if="component.type == 'text' && !component.properties.multiple_lines"
            :id="'component_' + component.id"
            v-model="inputValue"
            @input="updateData($event)"
            @keyup="checkKeys($event)"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false"
            tabindex="-1"
            class="tabable">
    <small class="error" v-if="component.type == 'text'" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>

    <div v-if="this.component.type == 'mail'" class="badge"><i class="icon-mail"></i></div>
    <input type="email"
            v-if="component.type == 'mail'"
            :id="'component_' + component.id"
            v-model="inputValue"
            @input="updateData($event)"
            @keyup="checkKeys($event)"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false"
            tabindex="-1"
            class="tabable">
    <small class="error" v-if="this.component.type == 'mail'" v-show="component.required_show_label">{{ messages.valid_email }}</small>

    <input type="text"
            v-if="this.component.type == 'number'"
            :id="'component_' + component.id"
            v-model="inputValue"
            inputmode="numeric"
            @input="updateData($event)"
            @keyup="checkKeys($event)"
            v-restrict-input.number
            v-mask="numberMask" 
            :placeholder="replaceWithZeros(numberMask)"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false"
            maxlength="15"
            tabindex="-1"
            class="tabable">
    <small class="error" v-if="this.component.type == 'number'" v-show="component.required_show_label">{{ messages.valid_number }} {{ numberValidationMessage }}</small>

    <div v-if="this.component.type == 'date'" class="badge"><i class="icon-date"></i></div>
    <input type="text"
            v-if="this.component.type == 'date'"
            :id="'component_' + component.id"
            v-model="inputValue"
            @input="updateData($event)"
            @keyup="checkKeys($event)"
            v-mask="'##/##/####'"
            placeholder="dd/mm/rrrr"
            tabindex="-1"
            class="tabable"
            inputmode="numeric"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false">
    <small class="error" v-if="this.component.type == 'date'" v-show="component.required_show_label">{{ messages.valid_date }}</small>

    <div v-if="this.component.type == 'website'" class="badge"><i class="icon-url"></i></div>
    <input type="url"
            v-if="component.type == 'website'"
            :id="'component_' + component.id"
            v-model="inputValue"
            @input="updateData($event)"
            @keyup="checkKeys($event)"
            v-focus="component.is_focused"
            @focus="component.is_focused = true"
            @blur="component.is_focused = false"
            tabindex="-1"
            class="tabable">
    <small class="error" v-if="this.component.type == 'website'" v-show="component.required_show_label">{{ messages.valid_website }}</small>

    <input 
      v-if="this.component.type == 'phone_number'"
      v-model="inputValue"
      @input="updateData($event)"
      v-focus="component.is_focused"
      @focus="component.is_focused = true"
      @blur="component.is_focused = false"
      type="tel"
      :id="'phone-' + component.id" 
      class="tabable" 
      tabindex="-1">
    <small class="error" v-if="this.component.type == 'phone_number'" v-show="component.required_show_label">{{ messages.valid_phone_number }}</small>

  </div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

import { mixin as focusMixin }  from 'vue-focus'

import restrictInput from '../directives/restrictInput'
import { VueMaskDirective } from 'v-mask'
import validationMixin from '../mixins/validationMixin'

export default {
  name: 'textType',
  props: ['component'],
  mixins: [validationMixin, focusMixin],
  directives: {
    mask: VueMaskDirective,
    restrictInput
  },
  data: function () {
    return {
      store: store,
      inputValue: '',
      messages: window.form.messages,
      mobileInput: null,
      appEnv: process.env
    }
  },
  created: function () {
    Vue.set(this.component, 'required_show_label', false)
    Vue.set(this.component, 'is_focused', false)
  },
  mounted : function() {
    if (this.component.type == "phone_number")
      this.createMobile()
  },
  computed: {
    numberMask: function () {
      if (this.component.properties.hasOwnProperty('mask') && this.component.properties.mask.length > 0) {
        return this.component.properties.mask
      } else {
        return false
      }
    },
    numberValidationMessage: function () {
      let min = ''
      let max = ''
      let format = ''

      if (this.component.properties.mask && this.component.properties.mask.length > 0) {
        format =  ' (' + this.replaceWithZeros(this.component.properties.mask) + ') '
      }

      if (this.component.validations.hasOwnProperty('min_value') && this.component.validations.min_value) {
        min = 'Min ' + this.component.validations.min_value
      }
      if (this.component.validations.hasOwnProperty('max_value') && this.component.validations.max_value) {
        max = ', Max ' + this.component.validations.max_value
      }
      return format + min + max
    }
  },
  watch: {
    component: {
      handler: function(val) {
        if (val.is_focused == false) {
          if (this.component.is_valid == false) {
            Vue.set(this.component, 'required_show_label', 1)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    updateData: function (e) {
      let value = this.inputValue

      if (this.component.type == 'phone_number') {
        value = this.mobileInput.getNumber()
      }

      if (this.component.properties.hasOwnProperty('mask') && this.component.properties.mask.length > 0) {
        value = value.replace(/\D/g,'')
      }
      
      if (value) {
        let obj = {
          "component": {
              "id": this.component.id,
              "type": this.component.type
          },
          "type": this.component.type
        }

        obj[this.component.type] = value

        Vue.set(this.store.answers, this.component.ref, obj)

        if (this.validateType(value, this.component.type)) {
          Vue.set(this.component, 'is_valid', true)
          Vue.set(this.component, 'required_show_label', 0)
          this.$emit('verifyPage')
        } else {
          Vue.set(this.component, 'is_valid', false)
        }

        if (this.component.type == 'number') {
          if (this.component.validations.hasOwnProperty('min_value') && this.component.validations.min_value) {
            if (value < this.component.validations.min_value) {
              Vue.set(this.component, 'is_valid', false)
              Vue.set(this.component, 'required_show_label', 1)
            }
          }
          if (this.component.validations.hasOwnProperty('max_value') && this.component.validations.max_value) {
            if (value > this.component.validations.max_value) {
              Vue.set(this.component, 'is_valid', false)
              Vue.set(this.component, 'required_show_label', 1)
            }
          }
          if (this.component.properties.hasOwnProperty('mask') && this.component.properties.mask.length > 0) { 
            if (this.countDigits(value) !== this.countHashSymbols(this.component.properties.mask)) {
              Vue.set(this.component, 'is_valid', false)
              Vue.set(this.component, 'required_show_label', 0)
            }
          }
        }

      } else {

        Vue.delete(this.store.answers, this.component.ref)
        if (this.component.validations.required) {
          Vue.set(this.component, 'required_show_label', 1)
        } else {
          Vue.set(this.component, 'is_valid', true)
          Vue.set(this.component, 'required_show_label', 0)
        }

      }

      Event.$emit('verifyPage')

    },
    countDigits(str) {
      const digitRegex = /\d/g
      const matches = str.match(digitRegex)
      return matches ? matches.length : 0
    },
    countHashSymbols(str) {
      const hashRegex = /#/g
      const matches = str.match(hashRegex)
      return matches ? matches.length : 0
    },
    replaceWithZeros(str) {
      if (str.length > 0) {
        return str.replace(/#/g, '0')
      } else {
        return ''
      }
    },
    checkKeys: function (e) {
      if (this.component.type != 'text' && !this.component.properties.multiple_lines) {
        if (e.keyCode == 13) {
          Event.$emit('verifyAndSkipPage')
        }
      }
    },
    autosize: function (event) {
      this.updateData(event)
      setTimeout(function () {
          event.target.style.height = 'auto';
          event.target.style.height = event.target.scrollHeight + 'px';
      }, 0)
    },
    createMobile() {
      let country = 'pl'
        
      let allowDropdown = true
      if (this.component.properties.country) {
        country = this.component.properties.country
      }
      
      let script
      if (this.appEnv.VUE_APP_LOCALE == 'en') {
        script = "../../js/utils.js"            
      } else {
        script = "/app-assets/utils.js"
      }
          
      const input = document.querySelector("#phone-" + this.component.id);
      var iti =  intlTelInput(input, {
          utilsScript: script,
          initialCountry: country,
          allowDropdown: allowDropdown,
          customContainer: 'mobile-input',
      });
      let self = this
      input.addEventListener("countrychange", function() {
        self.updateData()
      })
      this.mobileInput = iti
    },
  }
}
</script>