<template>
<div class="component" :id="'component_' + component.ref" v-if="component.type != 'group'">

    <h2 v-if="component.type != 'legal'" v-html="applyVariables(title)"></h2>
    <mediabox v-if="component.media" :media="component.media" :viewport-width="viewportWidth" :activeBlock="activeBlock"></mediabox>
    <div class="question-note" v-if="component.properties.description && component.type != 'legal'" v-html="applyVariables(description)"></div>

    <text-type :component="component" v-if="component.type == 'text' ||
                                            component.type == 'number' || 
                                            component.type == 'date' || 
                                            component.type == 'website' || 
                                            component.type == 'phone_number' || 
                                            component.type == 'mail'"></text-type>

    <legal-type :component="component" v-if="component.type == 'legal'" :description="description"></legal-type>

    <upload-type :component="component" v-if="component.type == 'file_upload' ||
                                                component.type == 'signature'" :formId="form.id"></upload-type>

    <yesno-type :component="component" v-if="component.type == 'yesno'"></yesno-type>

    <choice-type :component="component" v-if="component.type == 'multiple_choice' || 
                                              component.type == 'picture_choice'"></choice-type>

    <ranking-type :component="component" v-if="component.type == 'ranking'"></ranking-type>

    <matrix-type :component="component" v-if="component.type == 'matrix'"></matrix-type>

    <dropdown-type :component="component" v-if="component.type == 'dropdown'"></dropdown-type>

    <rating-type :component="component" v-if="component.type == 'opinion_scale' || 
                                              component.type == 'customer_sat' || 
                                              component.type == 'rating'"></rating-type>

    <numberChoiceType :component="component" v-if="component.type == 'slider' || 
                                                   component.type == 'constant_sum' || 
                                                   component.type == 'semantic_differential' || 
                                                   component.type == 'numbers_scale'"></numberChoiceType>

</div>
</template>
<script>
import mediabox from './mediabox'
import textType from './textType'
import uploadType from './uploadType'
import choiceType from './choiceType'
import rankingType from './rankingType'
import matrixType from './matrixType'
import yesnoType from './yesnoType'
import legalType from './legalType'
import numberChoiceType from './numberChoiceType'
import dropdownType from './dropdownType'
import ratingType from './ratingType'
import pipingMixin from '../mixins/pipingMixin'
import { store } from '../store'

export default {
  name: 'componentSelector',
  props: ['component', 'viewportWidth', 'form', 'number', 'activeBlock'],
  mixins: [pipingMixin],
  components: {
      mediabox,
      textType,
      uploadType,
      legalType,
      yesnoType,
      choiceType,
      matrixType,
      dropdownType,
      ratingType,
      rankingType,
      numberChoiceType
  },
  data: function () {
    return {
      componentTitle: '',
      componentDescription: ''
    }
  },
  created: function () {
    this.componentTitle = this.component.title.replace(/(<([^>]+)>)/gi, "")
    this.componentDescription = this.component.properties.description.replace(/(<([^>]+)>)/gi, "")
  },
  computed: {
    title: function () {
      let show_number = '', required = ''
      if (this.form.settings.enable_components_numbers) {
        show_number = '<span>' + this.number + '.</span>'
      }
      if (this.component.validations.required && this.form.settings.enable_components_required_asterisk) {
        required = '<span class="red">*</span>'
      }
      return show_number + ' ' + this.applyVariables(this.componentTitle) + required
    },
    description: function () {
      return this.applyVariables(this.componentDescription)
    }
  },
  data: function () {
    return {
        store: store
    }
  }
}
</script>

