import Vue from 'vue'
var logicMixin = {
  methods: {
    calculateVariables: function(goingBack = true) {

      // remove calculate rules if going back
      if(goingBack) {
        for (let i = 0; i < this.store.calculations.length; i++) {
          if (i >= this.currentPage) {
            this.store.calculations.splice(i, 1)
          }
        }
      }
      
      // set init zeros
      let result = []
      for (let i = 0; i < this.form.variables.length; i++) {
        let name = this.form.variables[i]
        result[name] = 0
      }

      // calculate variables
      for (let i = 0; i < this.store.calculations.length; i++) {
        if (this.store.calculations[i] && this.store.calculations[i].length > 0) {
          for (let ii = 0; ii < this.store.calculations[i].length; ii++) {
            let calc = this.store.calculations[i][ii]
            if (calc) {
              let value = 0
              if (calc.value && calc.value.type == 'component') {
                  if (this.store.answers.hasOwnProperty(calc.value.value)) {
                      value = this.store.answers[calc.value.value].number
                  } 
              } else {
                  value = calc.value.value
              }
              
              switch (calc.action) {
                  case 'add':
                      result[calc.target.value] = (Number(result[calc.target.value]) + Number(value)) * 100 / 100
                      break
                  case 'subtract':
                      result[calc.target.value] = (Number(result[calc.target.value]) - Number(value)) * 100 / 100
                      break
                  case 'multiply':
                      result[calc.target.value] = (Number(result[calc.target.value]) * Number(value)) * 100 / 100
                      break
                  case 'divide':
                      result[calc.target.value] = (Number(result[calc.target.value]) / Number(value)) * 100 / 100
                      break
              }

              result[calc.target.value] = Number(result[calc.target.value].toFixed(2))
            }
          }
        }
      }
      Vue.set(this.store, 'variables', result)
    },
    computeConditions: function(rules) {
      if (rules) {

        // rules.actions.forEach((element, index, array) => {
        //   if(element.action === 'jump') {
        //     rules.actions.push(element);
        //     array.splice(index,1)
        //   }
        // }); // TODO: explain that
        
        let conditionsOk = false
        for (let i = 0; i < rules.actions.length; i++) {
         
          conditionsOk = false
          if (rules.actions[i].condition.op == 'always') {
            conditionsOk = true
       
          } else {
            if (rules.actions[i].condition.op == 'and' || rules.actions[i].condition.op == 'or') {
              let firstLevel = []
              for (let ii = 0; ii < rules.actions[i].condition.vars.length; ii++) {
              
                if (rules.actions[i].condition.vars[ii].op == 'and' || rules.actions[i].condition.vars[ii].op == 'or') {
  
                  let secondLevel = []
                  for (let iii = 0; iii < rules.actions[i].condition.vars[ii].vars.length; iii++) {
  
                    if (this.checkCondition(rules.actions[i].condition.vars[ii].vars[iii])) {
                      secondLevel.push(true)
                    } else {
                      secondLevel.push(false)
                    } 
                  }
  
                  if (rules.actions[i].condition.vars[ii].op == 'and' && !_.includes(secondLevel, false)) {
                    firstLevel.push(true)
                  }
                  if (rules.actions[i].condition.vars[ii].op == 'or' && _.includes(secondLevel, true)) {
                    firstLevel.push(true)
                  }
                
                } else {
                  if (this.checkCondition(rules.actions[i].condition.vars[ii])) {
                    firstLevel.push(true)
                  } else {
                    firstLevel.push(false)
                  }
                }
              }
  
              if (rules.actions[i].condition.op == 'and' && !_.includes(firstLevel, false)) {
                conditionsOk = true              
              }
              if (rules.actions[i].condition.op == 'or' && _.includes(firstLevel, true)) {
                conditionsOk = true
              }
  
            } else {
              if (this.checkCondition(rules.actions[i].condition)) {
                conditionsOk = true
              }
            }
          }

          if (conditionsOk) {
            
            if (rules.actions[i].action == 'jump' && !this.logicJumpTo) {
              this.logicJumpTo = rules.actions[i].details.to
            }

            if (rules.actions[i].action != 'jump') {
              let calcArray 
              if (this.store.calculations[this.currentPage]) {
                calcArray = this.store.calculations[this.currentPage]
              } else {
                calcArray = []
              }
              
              calcArray.push({
                action: rules.actions[i].action,
                value: rules.actions[i].details.value,
                target: rules.actions[i].details.target
              })

              Vue.set(this.store.calculations, this.currentPage, calcArray)
            }
          }
        }
      }
    },
    checkCondition: function(condition) {
      let var1, var2, storeVal
      if (condition.vars && condition.vars[0].type == 'component') {
        if (this.store.answers[condition.vars[0].value]) {
          storeVal = this.store.answers[condition.vars[0].value]
        } else {
          return false
        }
        switch (storeVal.type) {
          case 'choices':
            var1 = storeVal.choices
            break
          case 'number':
            var1 = storeVal.number
            break
          case 'text':
            var1 = storeVal.text
            break
          case 'boolean':
            var1 = storeVal.boolean
            break
          case 'email':
            var1 = storeVal.email
            break
          case 'date':
            var1 = storeVal.date
            break
          case 'file_name':
            var1 = storeVal.file_name
        }
      } else if (condition.vars[0].type == 'hidden') {
        let hiddenName = condition.vars[0].value
        if (this.store.hidden[hiddenName]) {
          var1 = this.store.hidden[hiddenName].value
        } else {
          return false
        }
      } else if (condition.vars[0].type == 'variable') {
        this.calculateVariables(false)
        let variableName = condition.vars[0].value
        if (this.store.variables.hasOwnProperty(variableName)) {
          var1 = this.store.variables[variableName]
        } else {
          return false
        }
      }



      switch (condition.vars[1].type) {
        case 'constant':
          var2 = condition.vars[1].value 
          if (var1[0] && var1[0].hasOwnProperty('label')) {
            var1 = var1[0].label
          }
          break
        case 'choice':
          var2 = condition.vars[1].value 
          break
      }
      let vat1Date, vat2Date
      switch (condition.op) {
        // numeric / string
        case 'equal':
          if (var1 == var2) {
            return true
          }
          break
        case 'not_equal':
          if (var1 != var2) {
            return true
          }
          break
        // string
        case 'starts_with':
          if (var1.startsWith(var2)) {
            return true
          }
          break
        case 'ends_with':
          if (var1.endsWith(var2)) {
            return true
          }
          break
        case 'contains':
          if (var1.includes(var2)) {
            return true
          }
          break
        case 'not_contains':
          if (!var1.includes(var2)) {
            return true
          }
          break
        // numeric
        case 'grather_equal_than':
          if (Number(var1) >= Number(var2)) {
            return true
          }
          break
        case 'grather_than':
          if (Number(var1) > Number(var2)) {
            return true
          }
          break
        case 'lower_equal_than':
          if (Number(var1) <= Number(var2)) {
            return true
          }
          break
        case 'lower_than':
          if (Number(var1) < Number(var2)) {
            return true
          }
          break
        // choice
        case 'is':
          if (condition.vars[1].type == 'choice') {
            if (_.findIndex(var1, {ref: var2}) > -1) {
              return true
            }
          } else {
            if (var1 == var2) {
              return true
            }
          }
          break
        case 'is_not':
          if (condition.vars[1].type == 'choice') {
            if (_.findIndex(var1, {ref: var2}) == -1) {
              return true
            }
          } else {
            if (var1 !== var2) {
              return true
            }
          }
          break
        // date
        case 'on':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.toDateString() === vat2Date.toDateString()) {
            return true
          }
        case 'not_on':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.toDateString() !== vat2Date.toDateString()) {
            return true
          }
        case 'earlier_than':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.getTime() < vat2Date.getTime()) {
            return true
          }
        case 'earlier_than_or_on':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.getTime() <= vat2Date.getTime()) {
            return true
          }
        case 'later_than':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.getTime() > vat2Date.getTime()) {
            return true
          }
        case 'later_than_or_on':
          vat1Date = new Date(var1)
          vat2Date = new Date(var2)
          if (vat1Date.getTime() >= vat2Date.getTime()) {
            return true
          }
        // file
        case 'answered':
          if (var1) {
            return true
          }
          break
      }
      
    },
    getJumpIndexFromRef: function(value) {
      let indexToJump

      for (let i = 0; i < this.form.components.length; i++) {
        if (this.form.components[i].type == 'group' && this.form.components[i].components.length > 0) {
          for (let ii = 0; ii < this.form.components[i].components.length; ii++) {
            if (value.value == this.form.components[i].components[ii].ref) {
              indexToJump = i
            }    
          }
        }
        if (value.value == this.form.components[i].ref) {
          indexToJump = i
        }
      }
      
      if (indexToJump === undefined) {
        return false
      } else {
        return indexToJump
      }

    }
  }
}
export default logicMixin
