<template>
<div class="select-type matrix">
  
  <table>
    <thead>
      <tr>
        <td></td>
        <th v-for="(choice, key) in component.choices" :key="key">{{choice.label}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(question, key) in component.questions" :key="key">
        <th>{{question.label}}</th>
        <td v-for="(choice, key2) in component.choices" :key="key2">
          
          <input v-if="component.properties.matrix_type == 'single_choice'" type="radio" :value="choice.id"  :name="'question' + question.id + '-' + component.id" :id="'choice-' + question.id + '-' + choice.id" v-model="answers[key]">

          <input v-if="component.properties.matrix_type == 'multiple_choice'" type="checkbox" :value="choice.id" :id="'choice-' + question.id + '-' + choice.id"  v-model="answers[key]">

          <label :for="'choice-' + question.id + '-' + choice.id" v-if="component.properties.matrix_type != 'text'">
            <span>{{choice.label}}</span>
          </label>
          
          <span v-if="component.properties.matrix_type == 'text'">{{choice.label}}</span>
          <input v-if="component.properties.matrix_type == 'text'" type="text" v-model="answers[key][key2]" maxlength="500">

        </td>
      </tr>
    </tbody>
  </table>

  <small class="error right" v-show="component.required_show_label">{{ messages.answer_is_required }} {{ extraErrorMsg }}</small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import { mixin as focusMixin }  from 'vue-focus'

export default {
  name: 'matrixType',
  props: ['component'],
  mixins: [ focusMixin ],
  data: function () {
    return {
      store: store,
      extraErrorMsg: '',
      messages: window.form.messages,
      answers: [],
      result: [],
      loading: true
    }
  },
  watch: {
    answers: function (val) {

      let temp = []
      for (let i = 0; i < this.answers.length; i++) {
        let ttemp
        if (Array.isArray(this.answers[i])) {
          ttemp = []
          for (let ii = 0; ii < this.answers[i].length; ii++) {

            if (this.component.properties.matrix_type == 'text') {
              if (this.answers[i][ii]) {
                ttemp.push({
                  "id": this.component.choices[ii].id,
                  "value": this.answers[i][ii]
                })
              }
            } else {
              ttemp.push({
                "id": this.answers[i][ii],
                "value": true
              })
            }
          }
        } else {
          if (this.answers[i]) {
            ttemp = [{
              "id": this.answers[i],
              "value": true
            }]
          }
        }
      
        if (ttemp && ttemp.length > 0) {
          let temptemp = {
            "id": this.component.questions[i].id,
            "type": "choices",
            "choices": ttemp
          }
          temp.push(temptemp)
        }        
      }
      this.result = temp

      if (!this.loading) {
        this.saveAnswers()
      }
    }
  },
  created: function () {

    if (this.component.properties.matrix_type != 'single_choice') {
      for (let i = 0; i < this.component.questions.length; i++) {
        Vue.set(this.answers, i, [])
      }
    }

    setTimeout(function () {
      this.loading = false
    }.bind(this), 100)

    Vue.set(this.component, 'required_show_label', false)
    Vue.set(this.component, 'is_focused', false)

    if (this.component.validations.min_select) {
      this.extraErrorMsg = ' Min. ' + this.component.validations.min_select
    }
    if (this.component.validations.max_select) {
      this.extraErrorMsg = this.extraErrorMsg + ' Max. ' + this.component.validations.max_select
    }
    if (this.extraErrorMsg != '') {
      this.extraErrorMsg = this.extraErrorMsg + ' ' + this.messages.answers + '.'
    }
  },
  methods: {
    saveAnswers: function () {

      let answerObject = {
        component: {
          id: this.component.id,
          type: 'matrix'
        },
        type: 'matrix',
        matrix: this.result
      }
      Vue.set(store.answers, this.component.ref, answerObject)
    
      let error = this.validateMatrix()

      if (error) {
        Vue.set(this.component, 'is_valid', false)
      } else {
        if (this.component.required_show_label == true) {
          Vue.set(this.component, 'required_show_label', false)
        }
        Vue.set(this.component, 'is_valid', true)
      }
      
    },
    validateMatrix: function () {

      if (this.component.validations.required) {
        if (!this.store.answers[this.component.ref]) {
          return true
        }

        let rowCount = this.store.answers[this.component.ref].matrix.length
        if (rowCount < this.component.questions.length) {
          return true
        }

        for (let i = 0; i < rowCount; i++) {
          let cellCount = this.store.answers[this.component.ref].matrix[i].choices.length

          if (this.component.properties.matrix_type == 'text') {
              if (cellCount < this.component.choices.length) {
                return true
              }
          } else {
            if (cellCount < 1) {
              return true
            }
            if (this.component.validations.min_select) {
              if (cellCount < this.component.validations.min_select) {
                return true
              }
            }
            if (this.component.validations.max_select) {
              if (cellCount > this.component.validations.max_select) {
                return true
              }
            }
            
          }
          
        }
      }


    }
  }
}
</script>
