<script>
export default {
    name: 'logo',
    data () {
        return {
            appEnv: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>

<template>
    <div class="logo-wrapper">
        <div class="logo-icon">
            <div class="first-circle"></div>
            <div class="second-circle"></div>
        </div>
        <div v-if="appEnv == 'pl'" class="logo-wordmark" v-html="require(`!html-loader!../assets/svg/interankiety-wordmark.svg`)"></div>
        <div v-else class="logo-wordmark" v-html="require(`!html-loader!../assets/svg/responsly-wordmark.svg`)"></div>
    </div>
</template>

<style lang="scss">

.logo-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    padding: 5px 10px 5px 10px;

    .logo-icon {
        width: 30px;
        height: 30px;
        position: relative;
        background: none;
        margin: 0px 0px 0 0px;
        .first-circle,
        .second-circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            // background-color: #04d800;
            background-color: #4af547;
            position: absolute;
            top: 0;
            left: 0;
        }
        .second-circle {
            // background-color: #fff;
            background-color: #000;
        }
        .second-circle {
            -webkit-transform: scale(0.45);
            transform: scale(0.45);
        }
    }


    &:hover {
        .second-circle {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
            -webkit-animation: interanimacja 1.15s infinite ease-in-out;
            animation: interanimacja 1.15s infinite ease-in-out;
        }
        
    }

    .logo-wordmark {
        width: 120px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        padding-top: 5px;
        svg {
            width: 110px;
        }
    }
    
}
@-webkit-keyframes interanimacja {
  0%, 100% { -webkit-transform: scale(0.45) }
  50% { -webkit-transform: scale(0.1) }
}

@keyframes interanimacja {
  0%, 100% {
    transform: scale(0.45);
    -webkit-transform: scale(0.45);
  } 50% {
      transform: scale(0.1);
      -webkit-transform: scale(0.1);
    }
}
</style>