<style lang="scss">

    .choice-dropdown-container {
      position: relative;
      display: inline-block;
      // z-index: 10;
      .dropdown-button {
        position: relative;
        width: 60px;
        // height: 30px;
        background: white;
        color: black;
        padding: 0px 10px;
        border-radius: 5px;
        cursor: pointer;
        &:after {
          position: absolute;
          right: 0;
          top: 0px;
          font-family: 'responslycon';
          content: '\e804';
          font-size: 16px;
          color: inherit;
          text-align: center;
          width: 30px;
          pointer-events: none;
          box-sizing: border-box;
        }
      }
      .dropdown-list {
        display: block;
        background: white;
        border-radius: 5px;
        box-shadow: 0 8px 10px -3px rgb(0 0 0 / 40%);
        width: 60px;
        z-index: 9999;
        top: 0;
        position: absolute;
        border: 1px solid lightgray;
        cursor: pointer;
        ul {
          li {
            padding: 3px 10px; 
            background: transparent;
            border-bottom: 1px solid lightgray;
            border-radius: 0;
            color: black;
            text-align: center;
            margin: 0;
            &:hover {
              background: #f4f4f4;
              border-radius: 5px;
            }
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }
    }

</style>


<template>

<div class="choice-dropdown-container" v-on-clickaway="away">
    <div class="dropdown-button" @click="toggle($event)">
        <slot name="trigger"></slot>
    </div>
    <div class="dropdown-list" v-if="show" @click="toggle($event)">
    <ul>
        <slot name="content"></slot>

    </ul>
    </div>
</div>

</template>
<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  props: ['component'],
  mixins: [clickaway],
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    away: function () {
      this.show = false
    },
    toggle: function (e) {
        this.show = !this.show;
        if (this.show === false) {
            e.stopPropagation()
        } else {
            this.$emit('rankingDropdownStatus')
        }
    }
  }
}
</script>
