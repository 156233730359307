<template>
<div class="select-type">

  <div class="selectInput">
    <v-select  @input="updateData" :options="answersList" :placeholder="messages.choose_answer">
      <span slot="no-options">{{ messages.no_option }}</span>
    </v-select>
  </div>

  <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import { mixin as focusMixin }  from 'vue-focus'

import vSelect from 'vue-select'
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', ''),
  },
  OpenIndicator: {
    render: createElement => createElement('span', ''),
  },
})

export default {
  name: 'dropdownType',
  props: ['component'],
  mixins: [ focusMixin ],
  components: {
    vSelect
  },
  data: function () {
    return {
      store: store,
      messages: window.form.messages
    }
  },
  computed: {
    answersList: function () {
      if (this.component.properties.randomize) {
        return _.shuffle(this.component.choices)
      } else {
        return this.component.choices;
      }
    }
  },
  created: function () {
    Vue.set(this.component, 'required_show_label', false)
  },
  methods: {
    updateData: function (val) {
      if (val) {
        Vue.set(this.component, 'required_show_label', false)
        let answerObject = {
          component: {
            id: this.component.id,
            type: 'dropdown'
          },
          type: 'text',
          text: val.label
        }
        Vue.set(store.answers, this.component.ref, answerObject)
      } else {
        if (this.component.validations.required) {
          Vue.set(this.component, 'required_show_label', true)
        }
        Vue.delete(this.store.answers, this.component.ref)
      }
      Event.$emit('verifyPage')
    }
  }
}
</script>
<style lang="scss">
  
.v-select {
  background: #dfdfdf;
  border-radius: 5px;
  position: relative;
  font-family: inherit;
  box-sizing: border-box;
  .vs__dropdown-toggle {
    border: 0;
    box-shadow: none;
    .vs__actions {
      justify-content: flex-end;
      padding-right: 20px;
      width: 90px;
      .vs__clear {
        margin-right: 30px;
        font-family: "responslycon";
        color: inherit;
        &:before { 
          font-size: 24px;
          content: '\e80f'; 
        }
      }
    }
  }
  .vs__search {
    border-radius: 5px;
    border: 0;
    background: transparent;
    font-family: inherit;
    outline: none;
    &::placeholder {
      color: rgb(119, 119, 119);
    }
  }
  .vs__search, .vs__search:focus {
    line-height: 2.6;
    padding: 0 10px;
  }
  .vs__selected {
    padding: 8px 10px;
    & + .vs__search {
      display: none;
    }
  }
  .vs__open-indicator {
    cursor: pointer;
    &:after {
      font-family: 'responslycon';
      content: '\e804';
      font-size: 20px;
      color: inherit;
      text-align: center;
      width: 30px;
      pointer-events: none;
      box-sizing: border-box;
    }
  }
  .vs__dropdown-menu {
    z-index: 99999999;
    border: 0;
    max-height: 200px;
    box-shadow: none;
    top: calc(100%);
    max-width: 100%;
    li {
      background: #dfdfdf;
      border-radius: 0px;
      margin-bottom: 0px;
      padding: 10px 15px;
      max-width: 100%;
      height: auto;
      &.vs__dropdown-option {
        display: block;
        white-space: normal;
      }
      &:hover {
        background: #afafaf;  
      }
      &.vs__dropdown-option--highlight {
        color: #fff;
        background: #82bf56;
      }
  
      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

    }
  }
}
.vs--single.vs--open .vs__selected {
  line-height: 2.6;
}
</style>