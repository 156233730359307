import 'current-script-polyfill'
import Vue from 'vue'
import theForm from './formComponents/theForm'

Vue.config.productionTip = false

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (!Vue.config.devtools) {
  Sentry.init({
    dsn: 'https://e9a4c11ce22e43cd913e4f786f122646@sentry.io/5179051',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  })
}

window.Event = new Vue()

new Vue({
  render: h => h(theForm),
}).$mount('#form-container')
