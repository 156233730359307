var shakeElement = {
  methods: {
    shakeElement: function (evt) {
        if (!evt.currentTarget.classList.contains('shake') && !evt.currentTarget.classList.contains('animated')) {
            evt.currentTarget.className += " shake animated";
            setTimeout(function(){
                evt.target.classList.remove("animated");
                evt.target.classList.remove("shake");
            }, 1000);
        }
    },
    shakeUiElement: function (el) {
      if (!el.classList.contains('shake') && !el.classList.contains('animated')) {
        el.className += " shake animated";
          setTimeout(function(){
            el.classList.remove("animated");
            el.classList.remove("shake");
          }, 1000);
      }
    }
  }
}
export default shakeElement
