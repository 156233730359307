var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.component.type != 'group')?_c('div',{staticClass:"component",attrs:{"id":'component_' + _vm.component.ref}},[(_vm.component.type != 'legal')?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.applyVariables(_vm.title))}}):_vm._e(),(_vm.component.media)?_c('mediabox',{attrs:{"media":_vm.component.media,"viewport-width":_vm.viewportWidth,"activeBlock":_vm.activeBlock}}):_vm._e(),(_vm.component.properties.description && _vm.component.type != 'legal')?_c('div',{staticClass:"question-note",domProps:{"innerHTML":_vm._s(_vm.applyVariables(_vm.description))}}):_vm._e(),(_vm.component.type == 'text' ||
                                            _vm.component.type == 'number' || 
                                            _vm.component.type == 'date' || 
                                            _vm.component.type == 'website' || 
                                            _vm.component.type == 'phone_number' || 
                                            _vm.component.type == 'mail')?_c('text-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'legal')?_c('legal-type',{attrs:{"component":_vm.component,"description":_vm.description}}):_vm._e(),(_vm.component.type == 'file_upload' ||
                                                _vm.component.type == 'signature')?_c('upload-type',{attrs:{"component":_vm.component,"formId":_vm.form.id}}):_vm._e(),(_vm.component.type == 'yesno')?_c('yesno-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'multiple_choice' || 
                                              _vm.component.type == 'picture_choice')?_c('choice-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'ranking')?_c('ranking-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'matrix')?_c('matrix-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'dropdown')?_c('dropdown-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'opinion_scale' || 
                                              _vm.component.type == 'customer_sat' || 
                                              _vm.component.type == 'rating')?_c('rating-type',{attrs:{"component":_vm.component}}):_vm._e(),(_vm.component.type == 'slider' || 
                                                   _vm.component.type == 'constant_sum' || 
                                                   _vm.component.type == 'semantic_differential' || 
                                                   _vm.component.type == 'numbers_scale')?_c('numberChoiceType',{attrs:{"component":_vm.component}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }