<template>
<div class="select-type">

  <ul :class="listClasses">
    <li v-for="(choice, key) in answersList" :key="key">
      <input :type="inputType" :id="'choice-' + choice.id" :value="{ id: choice.id, ref: choice.ref, label: choice.label }" :name="'choice-' + component.id" @change="updateData($event, false)"  v-focus="component.is_focused" tabindex="-1" v-model="inputData">
      <label :for="'choice-' + choice.id" v-if="component.type == 'multiple_choice'" tabindex="-1" class="tabable">
          {{ choice.label }}
      </label>

      <label class="media tabable" :for="'choice-' + choice.id" v-if="component.type == 'picture_choice'" tabindex="-1" :title="choice.label">
          <div class="small-mediabox">
              <img v-if="choice.media.url" :src="urls.assets_url + choice.media.url" :style="setPosition(choice.media)">
          </div>
          <span>{{ choice.label }}</span>
      </label>
    </li>
    <li v-if="component.properties.allow_other_choice">
      <input :type="inputType" :id="component.id + 'other'" :value="{id: 'other', ref: 'other', label: ''}" @change="updateData($event, 'setOther')" v-model="inputData" tabindex="-1">
      <label :for="component.id + 'other'" tabindex="-1" class="tabable" :class="{'other-li':showOtherInput}">
          <span v-show="!showOtherInput">{{ component.properties.other_choice_label }}</span>
          <input v-show="showOtherInput" type="text" class="other-input" :placeholder="component.properties.other_choice_label" :name="other" @input="updateData($event, 'updateOther')" @keyup.enter="enterOtherKeyUp" v-model="inputDataOther" v-focus="focusOnOther" @focus="focusOnOther = true" @blur="focusOnOther = false" tabindex="-1">
      </label>
    </li>
  </ul>

  <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }} {{ extraErrorMsg }} <span v-if="extraErrorMsg">{{ messages.currently_selected }}: {{ currentlySelected }}</span></small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import { mixin as focusMixin }  from 'vue-focus'

export default {
  name: 'choiceType',
  props: ['component'],
  mixins: [ focusMixin ],
  data: function () {
    return {
      urls: window.urls,
      store: store,
      answersList: [],
      inputData: [],
      inputDataOther: '',
      focusOnOther: false,
      showOtherInput: false,
      extraErrorMsg: '',
      messages: window.form.messages
    }
  },
  computed: {
    currentlySelected () {
      if (this.store.answers.hasOwnProperty(this.component.ref) && this.store.answers[this.component.ref].choices) {
        return this.store.answers[this.component.ref].choices.length
      } else {
        return '0'
      }
    },
    other: function () {
        return this.component.id + '_other'
    },
    listClasses: function () {
      let classes = ''
        if (this.component.type == 'picture_choice') {
            classes += 'picture-thumbs-list'
        }
        if (this.component.properties.horizontal_alignment) {
            classes += ' horizontal'
        }
        if (this.component.properties.large_thumbs) {
            classes += ' large'
        }
        if (this.component.properties.hide_labels) {
            classes += ' hide-labels'
        }
        return classes
    },
    inputType: function () {
      if (this.component.properties.allow_multiple_selection) {
        return 'checkbox'
      } else {
        return 'radio'
      }
    }
  },
  created: function () {

    if (this.component.properties.randomize) {
      this.answersList = _.shuffle(this.component.choices)
    } else {
      this.answersList = this.component.choices
    }    

    Vue.set(this.component, 'required_show_label', false)
    Vue.set(this.component, 'is_focused', false)

    if (this.component.validations.min_select) {
      this.extraErrorMsg = ' Min. ' + this.component.validations.min_select
    }
    if (this.component.validations.max_select) {
      this.extraErrorMsg = this.extraErrorMsg + ' Max. ' + this.component.validations.max_select
    }
    if (this.extraErrorMsg != '') {
      this.extraErrorMsg = this.extraErrorMsg + ' ' + this.messages.answers + '.'
    }
  },
  methods: {
    enterOtherKeyUp: function (e) {
      Event.$emit('verifyAndSkipPage')
    },
    updateData: function (e, other) {
      let error, silentError = false

      // handle other field
      if (other == 'setOther') { // check/radio other
        if (e.target.checked == false) {
          this.focusOnOther = false
          this.showOtherInput = false
        } else {
          this.focusOnOther = true
          this.showOtherInput = true
        }
      } else if (other == 'updateOther') { // input update

      } else { // check radio/other
        if (!this.component.properties.allow_multiple_selection) { 
          this.showOtherInput = false
        }
      }

      // set value
      if (this.inputData.length == 0) {
        Vue.delete(this.store.answers, this.component.ref)
      } else {
        let selectedChoices = []
        if (Array.isArray(this.inputData)) {
          selectedChoices = JSON.parse(JSON.stringify(this.inputData))
        } else {
          selectedChoices = [JSON.parse(JSON.stringify(this.inputData))]
        }
        let otherIndex = _.findIndex(selectedChoices, {'id': 'other'})
        if (otherIndex > -1) {
          selectedChoices[otherIndex].label = this.inputDataOther
          if (this.inputDataOther.length == 0) {
            silentError = true
          }
        }
        let answerObject = {
          component: {
            id: this.component.id,
            type: 'multiple_choice'
          },
          type: 'choices',
          choices: selectedChoices
        }
        Vue.set(store.answers, this.component.ref, answerObject)
      }

      // check validations
      if (this.component.validations.required) {
        if (!this.store.answers[this.component.ref]) {
          error = true
        }
        if (this.component.validations.min_select && this.store.answers[this.component.ref]) {
          if (this.store.answers[this.component.ref].choices.length < this.component.validations.min_select) {
            error = true
          }
        }
        if (this.component.validations.max_select && this.store.answers[this.component.ref]) {
          if (this.store.answers[this.component.ref].choices.length > this.component.validations.max_select) {
            error = true
          }
        }
      }

      // set flags
      if (error) {
          Vue.set(this.component, 'required_show_label', true)
          Vue.set(this.component, 'is_valid', false)
      } else {
          if (silentError) {
            Vue.set(this.component, 'is_valid', false)
          } else {
            Vue.set(this.component, 'required_show_label', false)
            Vue.set(this.component, 'is_valid', true)
          }
      }

      Event.$emit('verifyPage')
    },
    setPosition: function (media) {
        return 'transform: matrix(' + media.scale + ', 0, 0, ' + media.scale + ', ' + media.pos_x + ', ' + media.pos_y + '); transform-origin: center center 0; transition: none;'
    }
  }
}
</script>
