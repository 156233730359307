<style lang="scss">
  .ranking-type {
    ul {
      li {
        position: relative;
        background-color: #f4f4f4;
        border-radius: 5px;
        margin-bottom: 10px;
        &:hover {
         background-color: rgba(204,204,204,0.8); 
        }
        label {
          padding: 5px 35px 5px 10px;
          border-radius: 5px;
          cursor: grab;
          min-height: 40px;
          position: relative;
          display: block;
          font-size: 18px;
          line-height: 30px;
          select {
            background-color: white;
            padding: 4px 10px;
            margin: 0;
            border-radius: 5px;
            display: inline-block;
            width: auto;
            font-size: 16px;
          }
          .label {
            padding: 0 25px 0 15px;
            position: relative;
          }
          .handler {
            position: absolute;
            right: 25px;
            top: 6px;
          }
        }
      }
    }
    
    .sortable-ghost {
      opacity: 1;
    }
    .sortable-chosen {
      opacity: 0;
    }
    &.toogleDropdown {
     .sortable-chosen {
        opacity: 1;
      } 
    }
    .sortable-drag {
      opacity: 1;
      cursor: pointer;
    }


  }
</style>
<template>
<div class="ranking-type" :class="{'toogleDropdown': dropdownOpened}">
  <ul>
    <draggable :list="answersList" class="dragArea" @change="onEnd" @start="dropdownOpened = false">
      <li class="list-group-item" v-for="item, index in answersList" :key="index">
          <label>

            <dropdown v-bind:onright="true" v-on:rankingDropdownStatus="toogleDropdownStatus">
                <div slot="trigger">
                  <span v-if="item.order !== ''">{{ item.order + 1 }}</span>
                  <span v-else>-</span> <i class="fa fa-arrow-down"></i>
                </div>
                <div slot="content">
                  <li v-for="item2, index2 in dropdownList" @click="sortElements(index, index2)" :key="index2">{{index2 + 1}}</li>
                </div>
            </dropdown>

            <span class="label">{{item.label}}</span>

            <div class="handler">
              <svg height="14" viewBox="0 0 10 14" width="10"><g fill="currentColor"><circle cx="2" cy="2" r="1.5"></circle><circle cx="2" cy="7" r="1.5"></circle><circle cx="2" cy="12" r="1.5"></circle><circle cx="8" cy="2" r="1.5"></circle><circle cx="8" cy="7" r="1.5"></circle><circle cx="8" cy="12" r="1.5"></circle></g></svg>
            </div>

          </label>
      </li>
    </draggable>

  </ul>

  <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import draggable from 'vuedraggable'
import dropdown from './rankingTypeDropdown'

export default {
  name: 'rankingType',
  props: ['component'],
  components: {
    draggable,
    dropdown
  },
  data: function () {
    return {
      store: store,
      dropdownList: [],
      answersList: [],
      inputData: [],
      inputDataOther: '',
      focusOnOther: false,
      showOtherInput: false,
      extraErrorMsg: '',
      messages: window.form.messages,
      dropdownOpened: false
    }
  },
  created: function () {

    if (this.component.properties.randomize) {
      this.answersList = _.shuffle(this.component.choices)
    } else {
      this.answersList = this.component.choices
    }
    
    // set default order
    for(let i = 0; i < this.answersList.length; i++) {
      Vue.set(this.answersList[i], 'order', '')
      Vue.set(this.dropdownList, i, i + 1)
    }

    Vue.set(this.component, 'required_show_label', false)
    Vue.set(this.component, 'is_focused', false)

  },
  methods: {
    onEnd: function(evt){
      this.dropdownList = []
      for(let i = 0; i < this.answersList.length; i++) {
        Vue.set(this.answersList[i], 'order', i)
        Vue.set(this.dropdownList, i, i + 1)
      }
      this.updateData()
    },  
    toogleDropdownStatus: function() {
      this.dropdownOpened = true
    },
    sortElements: function(old_index, new_index) {
      this.answersList.splice(new_index, 0, this.answersList.splice(old_index, 1)[0])
      this.onEnd()
    },
    updateData: function (e, other) {
      let error, silentError = false

      let selectedChoices = []
      for(let i = 0; i < this.answersList.length; i++) {
        selectedChoices[i] = {
          'id': this.answersList[i].id,
          'label': this.answersList[i].label,
        }
      }
      
      let answerObject = {
        component: {
          id: this.component.id,
          type: 'ranking'
        },
        type: 'choices',
        choices: selectedChoices
      }
      Vue.set(store.answers, this.component.ref, answerObject)
 
      // check validations
      if (this.component.validations.required) {
        if (!this.store.answers[this.component.ref]) {
          error = true
        }
      }

      // set flags
      if (error) {
          Vue.set(this.component, 'required_show_label', true)
          Vue.set(this.component, 'is_valid', false)
      } else {
          if (silentError) {
            Vue.set(this.component, 'is_valid', false)
          } else {
            Vue.set(this.component, 'required_show_label', false)
            Vue.set(this.component, 'is_valid', true)
          }
      }

      Event.$emit('verifyPage')
    }
  }
}
</script>
