<template>
  <div class="animated-dots">{{ dots }}</div>
</template>
<script>
export default {
  name: "inlineLoading",
  data: function () {
    return {
      dots: ''
    }
  },
  created () {
    let self = this
    window.setInterval( function() {    
    if ( self.dots.length > 3 ) 
        self.dots = ""
    else 
        self.dots += "."
    }, 200)
  }
};
</script>
<style>
.animated-dots {
  display: inline-block;
  width: 20px;
  text-align: left;
}

</style>