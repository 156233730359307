<script>

export default {
    name: 'svg-icon',
    props: {
        icon: {
            type: String,
            default: null
        }
    }
}
</script>

<template>
    <div v-html="require(`!html-loader!../../assets/svg/${icon}.svg`)" class="svg-container"></div>
</template>

<style lang="scss" scoped>

</style>