<template>
<div class="open-type">
  <div class="file-type" v-if="component.type == 'file_upload'">
    <label v-show="uploadStatus == 'select'" class="file-upload tabable" :for="'file-chooser-' + component.id" tabindex="-1">{{ messages.upload_file }}</label>

    <input type="file" @change="processFile($event)" :id="'file-chooser-' + component.id" class="fileUpload" tabindex="-1">

    <div v-if="uploadStatus == 'upload'" class="uploadProgress">{{ messages.uploading }} <inlineLoading></inlineLoading></div>

    <div v-if="uploadStatus == 'uploaded'" class="uploadedFile"><a :href="uploadedUrl" class="file">{{ messages.file_uploaded }}</a><button @click="removeFile" class="fileRemove"><i class="icon-close"></i></button></div>

  </div>
  <small class="error" v-if="component.type == 'file_upload'" v-show="component.required_show_label">{{ messages.upload_error }}</small>


  <div v-if="this.component.type == 'signature'" class="badge"><i class="signature"></i></div>
  <div v-if="component.type == 'signature'" ref="signature" class="signature-preview">
    <canvas :width="signatureWidth" height="200px" :id="'signature-' + component.id"></canvas>

    <div  class="signature-buttons">
      <div class="button-wrapper">
        <button @click="clearSignature" class="button-first">{{ messages.clear }}</button>
      </div>
      <div class="button-wrapper">
        <button v-if="uploadStatus == 'select'" @click="processFile">{{ messages.confirm }}</button>
        <button v-if="uploadStatus == 'upload'">{{ messages.uploading }} <inlineLoading></inlineLoading></button>
        <button v-if="uploadStatus == 'uploaded'" disabled>{{ messages.file_uploaded }}</button>
      </div>
    </div>
  </div>
  <small class="error" v-if="this.component.type == 'signature'" v-show="component.required_show_label">{{messages.answer_is_required}}</small>


</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'
import axios from 'axios'
import inlineLoading from './inlineLoading'
import SignaturePad from 'signature_pad'

export default {
  name: 'uploadType',
  props: ['component', 'formId'],
  components: {
    inlineLoading
  },
  data: function () {
    return {
      store: store,
      uploadStatus: 'select',
      selectedFile: '',
      uploadedUrl: '',
      messages: window.form.messages,
      signaturePad : null,
      signatureWidth : '300px',
    }
  },
  created: function () {
    Vue.set(this.component, 'required_show_label', false)
    Vue.set(this.component, 'is_focused', false)
  },
  mounted: function () {
    this.createSignaturePad()
  },
  watch: {
    component: {
      handler: function(val) {
        if (val.is_focused == false) {
          if (this.component.is_valid == false) {
            Vue.set(this.component, 'required_show_label', 1)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    processFile: function (event) {
      let self = this
      if (this.component.type == 'signature') {
        if ( this.signaturePad.isEmpty()) {
          Vue.set(this.component, 'is_valid', false)
          Vue.set(this.component, 'required_show_label', 1)
          return
        }
        let dataUrl = this.signaturePad.toDataURL()
        this.selectedFile = this.dataURLtoFile(dataUrl,'signature.png')
      }
      if (this.component.type == 'file_upload')
        this.selectedFile = event.target.files[0]
      let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain', 'image/tiff', 'application/x-rar-compressed', 'application/octet-stream', 'application/zip', 'application/octet-stream', 'audio/mpeg', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ]
      if (this.selectedFile && this.selectedFile.size < 1024*1024*5 && _.includes(allowedTypes, this.selectedFile.type)) {

        Vue.set(this.component, 'is_valid', true)
        Vue.set(this.component, 'required_show_label', 0)
        this.$emit('verifyPage')
        this.uploadStatus = 'upload'
        let formData = new FormData()
        formData.append('file', this.selectedFile)
        formData.append('formId', this.formId)

        let config = {
          method: 'post',
          mode: 'no-cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true,
          credentials: 'same-origin',
        }

        axios.post(window.file_upload_url,
            formData, config)
            .then(function (response) {

                self.uploadStatus = 'uploaded'
                let answerObject = {
                  component: {
                    id: self.component.id,
                    type: self.component.type
                  },
                  type: 'file_name',
                  file_name: response.data.href
                }
                Vue.set(self.store.answers, self.component.ref, answerObject)
                self.uploadedUrl = response.data.href

            })
            .catch(function (reason) {

                self.uploadStatus = 'select'
                Vue.delete(self.store.answers, self.component.ref)
                if (self.component.validations.required) {
                  Vue.set(self.component, 'is_valid', false)
                  Vue.set(self.component, 'required_show_label', 1)
                }
            })
            if (this.component.type == 'file_upload')
              document.getElementById('file-chooser-' + this.component.id).value = ''
      } else {
        Vue.set(this.component, 'is_valid', false)
        Vue.set(this.component, 'required_show_label', 1)
      }
    },
    removeFile: function () {
      this.uploadStatus = 'select'
      Vue.delete(this.store.answers, this.component.ref)
      this.uploadedUrl = '' 
      this.selectedFile = ''
    },
    createSignaturePad() {
      if ( this.component.type == 'signature') {
        const canvas = document.getElementById("signature-" + this.component.id)
        let width = this.$refs.signature.clientWidth
        this.signatureWidth = width + 'px'

        const signaturePad = new SignaturePad(canvas, {
          minWidth: 3,
          maxWidth: 5,
          background: '#ffffff',
        })
        signaturePad.addEventListener("endStroke", () => {
          this.inputValue = 'x'
          // this.updateData()
        });
        this.signaturePad = signaturePad
        window.addEventListener("resize", this.resizeCanvas);
      }
    },
    clearSignature() {
      this.signaturePad.clear()
      this.inputValue = null
      this.removeFile()
      // this.updateData()
    },
    resizeCanvas() {
      let width = this.$refs.signature.clientWidth
      this.signatureWidth = width + 'px'
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },
  }
}
</script>