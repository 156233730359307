const translations = {
    en: {
        lessThan30Seconds: "Less than 30 seconds",
        lessThan1Minute: "Less than 1 minute",
        minute: "minute",
        minutes: "minutes",
        timeToComplete: "Time to complete:",
      },
      pl: {
        lessThan30Seconds: "Mniej niż 30 sekund",
        lessThan1Minute: "Mniej niż 1 minuta",
        minute: "minuta",
        minutes: "minuty",
        timeToComplete: "Czas wypełniania:",
      },
      ru: {
        lessThan30Seconds: "Меньше 30 секунд",
        lessThan1Minute: "Меньше 1 минуты",
        minute: "минута",
        minutes: "минуты",
        timeToComplete: "Время для завершения:",
      },
      cz: {
        lessThan30Seconds: "Méně než 30 sekund",
        lessThan1Minute: "Méně než 1 minuta",
        minute: "minuta",
        minutes: "minuty",
        timeToComplete: "Čas do dokončení:",
      },
      fr: {
        lessThan30Seconds: "Moins de 30 secondes",
        lessThan1Minute: "Moins d'une minute",
        minute: "minute",
        minutes: "minutes",
        timeToComplete: "Temps restant :",
      },
      es: {
        lessThan30Seconds: "Menos de 30 segundos",
        lessThan1Minute: "Menos de 1 minuto",
        minute: "minuto",
        minutes: "minutos",
        timeToComplete: "Tiempo restante:",
      },
      no: {
        lessThan30Seconds: "Mindre enn 30 sekunder",
        lessThan1Minute: "Mindre enn 1 minutt",
        minute: "minutt",
        minutes: "minutter",
        timeToComplete: "Tid til fullføring:",
      },
      de: {
        lessThan30Seconds: "Weniger als 30 Sekunden",
        lessThan1Minute: "Weniger als 1 Minute",
        minute: "Minute",
        minutes: "Minuten",
        timeToComplete: "Verbleibende Zeit:",
      },
      sk: {
        lessThan30Seconds: "Menej než 30 sekúnd",
        lessThan1Minute: "Menej než 1 minúta",
        minute: "minúta",
        minutes: "minúty",
        timeToComplete: "Čas do ukončenia:",
      },
      se: {
        lessThan30Seconds: "Mindre än 30 sekunder",
        lessThan1Minute: "Mindre än 1 minut",
        minute: "minut",
        minutes: "minuter",
        timeToComplete: "Tid till färdigställande:",
      },
      ua: {
        lessThan30Seconds: "Менше 30 секунд",
        lessThan1Minute: "Менше 1 хвилини",
        minute: "хвилина",
        minutes: "хвилини",
        timeToComplete: "Час до завершення:",
      },
      hu: {
        lessThan30Seconds: "Kevesebb mint 30 másodperc",
        lessThan1Minute: "Kevesebb mint 1 perc",
        minute: "perc",
        minutes: "percek",
        timeToComplete: "Befejezésig hátralévő idő:",
      },
      it: {
        lessThan30Seconds: "Meno di 30 secondi",
        lessThan1Minute: "Meno di 1 minuto",
        minute: "minuto",
        minutes: "minuti",
        timeToComplete: "Tempo rimanente:",
      },
  };

  export default translations;