<template>
<div>

  <div class="yesno-buttons" v-if="this.component.properties.answer_type == 'thumbs' || this.component.properties.answer_type == 'check' || this.component.properties.answer_type == 'emoji'">
    <input type="radio" :id="'yesno-' + component.id + '-yes'" :name="'yesno-' + component.id" @change="updateData" :value="true" v-model="inputData" tabindex="-1">
    <label :for="'yesno-' + component.id + '-yes'" tabindex="-1" class="tabable">
      <i :class="yesIcon.yes"></i>
    </label>

    <input type="radio" :id="'yesno-' + component.id + '-no'" :name="'yesno-' + component.id" @change="updateData" :value="false" v-model="inputData" tabindex="-1">
    <label :for="'yesno-' + component.id + '-no'" tabindex="-1" class="tabable">
      <i :class="yesIcon.no"></i>
    </label>
  </div>

  <div class="select-type" v-else>
    <ul>
      <li>
        <input type="radio" :id="'yesno-' + component.id + '-yes'" :name="'yesno-' + component.id" @change="updateData" :value="true" v-model="inputData" tabindex="-1">
        <label :for="'yesno-' + component.id + '-yes'" tabindex="-1" class="tabable">{{ messages.yes }}</label>
      </li>
      <li>
        <input type="radio" :id="'yesno-' + component.id + '-no'" :name="'yesno-' + component.id" @change="updateData" :value="false" v-model="inputData" tabindex="-1">
        <label :for="'yesno-' + component.id + '-no'" tabindex="-1" class="tabable">{{ messages.no }}</label>
      </li>
    </ul>
  </div>

  <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'

export default {
  name: 'yesnoType',
  props: ['component'],
  data: function () {
    return {
      store: store,
      messages: window.form.messages,
      inputData: ''
    }
  },
  computed: {
    yesIcon: function () {
      if (this.component.properties.answer_type == 'thumbs') {
        return {
          yes: 'icon-thumbs-up',
          no: 'icon-thumbs-down'
        }
      } else if (this.component.properties.answer_type == 'emoji') {
        return {
          yes: 'emoji-yes',
          no: 'emoji-no'
        }
      } else {
        return {
          yes: 'icon-yes',
          no: 'icon-close'
        }
      }
    }
  },
  created: function () {
    Vue.set(this.component, 'required_show_label', false)
  },
  methods: {
    updateData: function (e) {
      let val = this.inputData
      if (val == true || val == false) {
        Vue.set(this.component, 'required_show_label', false)
        let answerObject = {
          component: {
            id: this.component.id,
            type: this.component.type
          },
          type: 'boolean',
          boolean: val
        }
        Vue.set(store.answers, this.component.ref, answerObject)
      } else {
        if (this.component.validations.required) {
          Vue.set(this.component, 'required_show_label', true)
        }
        Vue.delete(this.store.answers, this.component.ref)
      }
      Event.$emit('verifyPage')
    }
  }
}
</script>
<style lang="scss">
  .yesno-buttons {
    display: flex;
    input {
      appearance: none;
      -webkit-appearance: none;
      -webkit-border-radius:0px;
      opacity: 0;
      position: fixed;
      width: 0;
    }
    label {
      // background-color: rgb(236, 236, 236);
      border-radius: 10px;
      width: 130px;
      height: 120px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: center;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      i {
        font-size: 60px;
        font-style: normal;
        &.emoji-yes {
          &::before {
            content: '👍';
          }
        }
        &.emoji-no {
          &::before {
            content: '👎';
          }
        }
      }
      &:hover {
        // background-color: rgb(209, 209, 209);
      }
    }
    input:checked + label {
      // background: #6dab41;

      -webkit-animation-name: flash-effect;
      -webkit-animation-duration: 200ms;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-timing-function: ease-in-out;
      animation-name: flash-effect;
      animation-duration: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      i {
        // color: white;
      }
    }
  }

</style>