<template>
  <div class="question-media" :style="setFrame">
    <div v-if="media.type == 'image'" class="img-box" :style="setHeight">
        <img :src="urls.assets_url + media.url" :style="setPosition">
    </div>
    <iframe v-if="media.type == 'video' && movieEnabled" width="100%" height="100%" :src="setYoutubeUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</template>
<script>
export default {
  name: 'mediabox',
  props: ['media', 'viewportWidth', 'activeBlock'],
  data: function () {
    return {
      urls: window.urls,
      new_height: false,
      scale: 1,
      iframeInitHeight: 320,
      movieEnabled: false
    }
  },
  watch: {
    viewportWidth: function () {
      this.checkViewport()
    },
    activeBlock: function () {
      this.checkMovieEnable()
    }
  },
  created: function () {
    this.checkMovieEnable()
    this.checkViewport()
  },
  computed: {
      setFrame: function () {
          if (this.new_height) {
              var height = ' height: ' + (Number(this.new_height) + 20) + 'px;' ;
          } else {
              if (this.media.height) {
                  var initHeight = this.media.height;
              } else {
                  var initHeight = this.iframeInitHeight;
              }
              var height = ' height: ' + (Number(initHeight) + 20) + 'px;' ;
          }
          if (!this.media.frame) {
              return 'background: transparent !important;' + height;
          } else {
              return height;
          }
      },
      setPosition: function () {
        let matrix = ''
        let scl = this.media.scale
        let x = this.media.pos_x
        let y = this.media.pos_y
        matrix = matrix += "-ms-transform : matrix(" + scl + ", 0, 0," + scl + "," + x + "," + y + ");"
        matrix += "-webkit-transform : matrix(" + scl + ", 0, 0," + scl + "," + x + "," + y + ");"
        matrix += "transform : matrix(" + scl + ", 0, 0," + scl + "," + x + "," + y + ");"
        return matrix
      },
      setHeight: function () {
          if (this.new_height) {
              var scale = 'transform: scale(' + this.scale + '); transform-origin: 0 0;'
          } else {
              var scale = ' ';
          }
          return 'width: 680px; height: ' + this.media.height + 'px; overflow: hidden; position: relative; text-align: center;' + scale;
      },
      setYoutubeUrl: function () {
          return this.media.url;
      }
  },
  methods: {
    checkMovieEnable: function () {
      if (this.activeBlock) {
          this.movieEnabled = true
      } else {
        this.movieEnabled = false
      }
    },
    checkViewport: function () {
      var windowWidth = this.viewportWidth
      if (windowWidth < 600) {

        this.scale = windowWidth / 760
        if (this.media.height) {
            var initHeight = this.media.height
        } else {
            var initHeight = this.iframeInitHeight
        }
        this.new_height = Number(initHeight) * this.scale

      } else {
        this.new_height = false
        this.scale = 1
      }
    }
  }
}
</script>
<style lang="scss">
.question-media {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #f4f4f4;
  position: relative;
  // min-height: 180px;
  overflow: hidden;
  .img-box {
    img {
      width: 100%;
      max-width: none;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
