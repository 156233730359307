<template>
<div>

  <div class="select-type">
    <ul>
      <li>
        <input type="checkbox" :id="'legal-' + component.id" v-model="inputData" @change="updateData" tabindex="-1">
        <label :for="'legal-' + component.id" tabindex="-1" class="tabable">
          {{ component.title }}
        </label>
      </li>
    </ul>
    <div class="question-note" v-if="component.properties.description" v-html="description"></div>
  </div>

  <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>

</div>
</template>
<script>
import Vue from 'vue'
import { store } from '../store'

export default {
  name: 'yesnoType',
  props: ['component', 'description'],
  data: function () {
    return {
      store: store,
      inputData: false,
      messages: window.form.messages
    }
  },
  created: function () {
    Vue.set(this.component, 'required_show_label', false)
  },
  methods: {
    updateData: function (e) {
      let val = this.inputData
      if (val) {
        Vue.set(this.component, 'required_show_label', false)
        let answerObject = {
          component: {
            id: this.component.id,
            type: this.component.type
          },
          type: 'boolean',
          boolean: val
        }
        Vue.set(store.answers, this.component.ref, answerObject)
      } else {
        if (this.component.validations.required) {
          Vue.set(this.component, 'required_show_label', true)
        }
        Vue.delete(this.store.answers, this.component.ref)
      }
      Event.$emit('verifyPage')
    }
  }
}
</script>
<style lang="scss">
  
</style>